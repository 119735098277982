import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';
import { languageType } from '../../util/languageType';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { NamedRedirect } from '../../components';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

// This "content-only" component can be used in modals etc.
const CMSPageContent = props => {
  const { inProgress, error, data } = props;

  if (inProgress) {
    return null;
  }

  // We don't want to add h1 heading twice to the HTML (SEO issue).
  // Modal's header is mapped as h2
  const hasContent = data => typeof data?.content === 'string';
  const exposeContentAsChildren = data => {
    return hasContent(data) ? { children: data.content } : {};
  };
  const CustomHeading1 = props => <H1 as="h2" {...props} />;

  const hasData = error === null && data;
  const sectionsData = hasData ? data : null;

  return (
    <SectionBuilder
      {...sectionsData}
      options={{
        fieldComponents: {
          heading1: { component: CustomHeading1, pickValidProps: exposeContentAsChildren },
        },
        isInsideContainer: true,
      }}
    />
  );
};

export const CMSPageComponent = props => {
  const { params, pageAssetsData, inProgress, error, currentPageAssets } = props;
  const state = useSelector(state => state);
  const { currentUser, currentUserProgress } = state.user;

  if (currentUserProgress) return null;
  console.log('currentUserProgress', currentUserProgress);
  const isProfessional = currentUser?.attributes?.profile?.publicData?.userType === 'professional';
  const isParent = currentUser?.attributes?.profile?.publicData?.userType === 'parent';
  const isVerified = currentUser?.attributes?.emailVerified;
  const pageId = currentPageAssets[0];
  if (typeof window !== 'undefined') {
    if (!currentUser && (pageId === 'abonnements' || pageId === 'abonnements-english')) {
      return <NamedRedirect name="LoginPage" />;
    }
    if (isParent && (pageId === 'abonnements' || pageId === 'abonnements-english')) {
      return <NamedRedirect name="LandingPage" />;
    }
    if (!isProfessional) {
      if (
        pageId === 'page-je-suis-un-professionnel' ||
        pageId === 'page-je-suis-un-professionnel-english'
      ) {
        if (currentUser) return <NamedRedirect name="LandingPage" />;
        else {
          // return <NamedRedirect name="LoginPage" />;
        }
      }
    } else if (
      isProfessional &&
      !isVerified &&
      (pageId === 'page-je-suis-un-professionnel' ||
        pageId === 'page-je-suis-un-professionnel-english')
    ) {
      return <NamedRedirect name="LandingPage" state={{ showVerificationPopup: true }} />;
    }
  }
  if (!inProgress && error?.status === 404) {
    return <NotFoundPage />;
  }

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[pageId]?.data}
      inProgress={inProgress}
      schemaType="Article"
    />
  );
};

CMSPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error, currentPageAssets } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error, currentPageAssets };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const CMSPage = compose(withRouter, connect(mapStateToProps))(CMSPageComponent);

export { CMSPageContent };

export default CMSPage;
